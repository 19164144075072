import React, { Fragment, Suspense, lazy, useEffect, useState } from "react";
import RTLSupport from "./styles/rtl-support";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ltrTheme, rtlTheme } from "./styles/theme";
import { BrowserRouter } from "react-router-dom";
import MerakiEditorListener from "./common/meraki-editor-listener";
import { ApolloProvider } from "@apollo/client";
import Routes from "./routes/online-ordering.routes";
import { useTranslation } from "react-i18next";
import { client } from "common/services/graphql/index";
import { connect } from "react-redux";
import { ThemeProvider } from "@urbanpiper-engineering/meraki-components";
import configSelector from "common/store/selectors/configSelector";
import upsdkService from "common/services/upsdkService";

const MixpanelHandler = lazy(
  () =>
    import(
      "./common/mixpanel-handler/index.component" /* webpackChunkName: "MixpanelHandler" */
    ),
);

const initialColors = {
  primaryColor: "#5b21b6",
  secondaryColor: "#6526c9",
  tertiaryColor: "#FACD5D",
  successColor: "#419D3E",
  failureColor: "#FF0000",
  primaryTextColor: "#000000",
  secondaryTextColor: "#303030",
  headerBackgroundColor: "#ffffff",
};

const defaultComponentTheme = {
  fontFamily: "Open Sans",
  colors: initialColors,
  isDarkTheme: false,
};

function App({ orderingType, auth, config }) {
  const [theme, setTheme] = useState(ltrTheme);
  const { i18n } = useTranslation();
  const direction = i18n.dir();
  const fontFamily = configSelector.getFontFamily({ config });
  const themeColors = configSelector.getThemeColors({ config });
  const [componentTheme, setComponentTheme] = useState(defaultComponentTheme);

  const [apolloClient, updateApolloClient] = useState(client.getInstance());

  // re-connect socket on user login
  // only if state.auth.data is changed
  useEffect(() => {
    if (auth) {
      updateApolloClient(client.getInstance(true));
    }
  }, [auth]);

  useEffect(() => {
    if (direction === "ltr") {
      setTheme(ltrTheme);
    } else {
      setTheme(rtlTheme);
    }
  }, [direction]);

  useEffect(() => {
    setComponentTheme({
      ...componentTheme,
      fontFamily: fontFamily || "Open Sans",
      colors: themeColors || initialColors,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, fontFamily, themeColors]);

  // set default fulfilmentMode
  useEffect(() => {
    const defaultMode = configSelector.getDefaultFulfilmentMode({ config });
    upsdkService.setFulfilmentType(defaultMode);
  }, []);

  return (
    <Fragment>
      <RTLSupport>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={componentTheme}>
            <Suspense fallback={null}>
              <MixpanelHandler />
            </Suspense>
            <BrowserRouter>
              <ApolloProvider client={apolloClient}>
                <MerakiEditorListener />
                <Routes orderingType={orderingType} />
              </ApolloProvider>
            </BrowserRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </RTLSupport>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth.data,
  config: state.config,
});

export default connect(mapStateToProps)(App);
